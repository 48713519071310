body {
  background: #fafafa;
}

@media (max-width: 991.98px) {
  a.brand-wrap {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .brand-wrap .logo,
  .navbar-brand .logo {
    position: relative !important;
    margin-top: inherit;
  }
}

@media (max-width: 767.98px) {
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    z-index: 10;
    text-align: left;
    /* width: 90vw; */
    background: white;
    right: 0px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
}

@media (max-width: 991.98px) {
  .navbar-main {
    /* margin-top: -43px; */
    margin-top: 8px;
    text-align: right;
    margin-bottom: 0px;
    height: 55px;
  }
  .footer-top .brand-wrap {
    height: 130px;
    align-items: inherit;
  }

  .no-padding {
    padding: 0px !important;
  }

  .no-margin {
    margin: 0px !important;
  }
  .header-main .widgets-wrap {
    position: relative;
    z-index: 90;
  }

  button.navbar-toggler {
    position: relative;
    z-index: 100;
  }
}
